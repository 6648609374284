<template>
    <div class="page_pointsmalldetail iphonex-pb" v-if="seriesId">
        <headeBar title="商品详情" left-arrow @click-left="$router.go(-1)" />
        <div class="goods_content">
            <div class="pointsquestion pub_pd">
                <van-icon name="question-o" size="28" @click="jumpQuestion" />
            </div>
            <div class="pub_pd base">
                <div class="card">
                    <img :src="cardInfo.img[0]" v-if="cardInfo.img" />
                </div>
                <div class="proname">{{ cardInfo.goodsName }}</div>
                <div class="introduce" v-if="cardInfo.itemIntroduce">
                    {{ cardInfo.itemIntroduce }}
                </div>
                <div
                    class="goods_tagsList"
                    v-if="cardInfo.tagsList && cardInfo.tagsList.length > 0"
                >
                    <div
                        class="goods_tagsList_item"
                        v-for="(item, index) in cardInfo.tagsList"
                        :key="index"
                    >
                        {{ item }}
                    </div>
                    <van-icon @click="showTip" size="40rpx" name="question-o" />
                </div>
            </div>
            <div
                class="pub_pd send pub_full pub_flexmiddle van-hairline--top"
                @click="getSendInfo"
                v-if="cardInfo.giftInfo && cardInfo.giftTitle"
            >
                <div class="send_goodsimg">
                    <img :src="cardInfo.giftImage" />
                </div>
                <div class="pub_onefull send_goodsname">
                    {{ cardInfo.giftTitle }}
                </div>
                <van-icon name="arrow" color="#242831" class="sendarrow" />
            </div>
            <div class="select pub_pd van-hairline--top" :class="{hidenum:acindex===-1}">
                <div class="select_title">选择面额</div>
                <div class="select_list">
                    <div
                        class="select_model"
                        @click="setIndex(item, index)"
                        :class="{ dis: item.inventory<=0, ac: index === acindex }"
                        v-for="(item, index) in cardList"
                        :key="item.goodsChildId"
                    >
                        {{ item.denomination }}
                    </div>
                </div>
                <div class="pub_avg pub_flexmiddle" v-if="acindex>-1" >
                    <div class="select_title npd">兑换数量</div>
                    <div class="numberop pub_full pub_flexmiddle">
                        <div
                            class="cosub"
                            @click="minus"
                            :class="{ disnum: num <= 1 }"
                        >
                            <van-icon name="minus" />
                        </div>
                        <div class="num">
                            <van-field
                                v-model="num"
                                type="digit"
                                input-align="center"
                                @input="numInput"
                                @change="numChange"
                            />
                        </div>
                        <div
                            class="cosub"
                            @click="plus"
                            :class="{
                                disnum:
                                    acindex === -1 ||
                                    num >= cardList[acindex].limitNum,
                            }"
                        >
                            <van-icon name="plus" />
                        </div>
                    </div>
                </div>
                <div
                    class="select_title"
                    v-if="cardInfo.giftInfo == 1 || cardInfo.giftInfo == 2"
                >
                    赠品卡数量 x{{ num }}
                </div>
            </div>
            <div
                class="info van-hairline--top"
                v-if="cardInfo.itemDetailsImg || rule.status"
            >
                <div class="pub_full pub_flexmiddle titleline">
                    <img
                        class="titlelogo"
                        src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/wxXiaoChengXu/giftCardStore/%E7%9F%A9%E5%BD%A2%20%282%29.png"
                    />
                    <div class="title">
                        {{ cardInfo.itemDetailsImg ? '商品详情' : rule.title }}
                    </div>
                    <img
                        class="titlelogo"
                        src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/wxXiaoChengXu/giftCardStore/%E7%9F%A9%E5%BD%A2%20%283%29.png"
                    />
                </div>
                <div class="pub_pd">
                    <img
                        :src="cardInfo.itemDetailsImg"
                        v-if="cardInfo.itemDetailsImg"
                    />
                    <div v-else style="white-space: pre-wrap;" >{{ rule.text }}</div>
                </div>
            </div>
        </div>

        <div class="footerbar iphonex-pb">
            <div class="pub_full subline">
                <div class="subleft">{{ total }}鹿分</div>
                <div v-if="total > points" class="subright dissub">
                    鹿分不足
                </div>
                <div v-else class="subright" :class="{dissub:acindex===-1}" @click="submit">立即兑换</div>
            </div>
        </div>
        <instructions ref="instructions" />
        <van-popup v-model="sendShow" round z-index="99999">
            <div class="sendbox">
                <div class="sendbox_img">
                    <img
                        v-if="sendPopInfo.giftImage"
                        :src="sendPopInfo.giftImage"
                    />
                </div>
                <div class="sendbox_title">赠品说明</div>
                <div class="sendbox_intro">{{ sendPopInfo.giftDesc }}</div>
                <div class="sendbox_sub" @click="sendShow = false">
                    我知道了
                </div>
            </div>
        </van-popup>
        <van-popup v-model="phoneShow" position="bottom" z-index="99999">
            <div class="phonelist">
                <a href="tel:15880444554" class="phoneline">
                    福州：谭先生 15880444554
                </a>
                <a href="tel:13105933131" class="phoneline">
                    厦门：游先生 13105933131
                </a>
                <div class="phonecancel" @click="phoneShow = false">取消</div>
            </div>
        </van-popup>
        <van-popup v-model="success" class="successbox" z-index="99999">
            <div class="successbox_close">
                <van-icon
                    name="cross"
                    size="20px"
                    color="#fff"
                    class="cross"
                    @click="success = false"
                />
            </div>
            <div class="pdc">
                <div>
                    <img
                        class="successbox_img"
                        src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/pointsMall/success.png"
                    />
                </div>
                <div class="successbox_title">兑换成功</div>
                <div class="successbox_tips">您可在“我的-礼品卡”中查看</div>
                <div class="successbox_sub" @click="success = false">
                    我知道了
                </div>
            </div>
        </van-popup>
        <van-popup v-model="confirmShow" class="confirmbox" z-index="99999">
            <div class="confirm_title">确认兑换</div>
            <div class="confirm_tips">确认是否使用{{total}}鹿分兑换【{{cardInfo.goodsName}}】x{{num}}</div>
            <div class="pub_avg" >
                <div class="confirmsub van-hairline--surround" @click="confirmShow=false">
                    我再想想
                </div>
                <div class="confirmsub subac" @click="buy">
                    继续兑换
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script>
import {
    gotoAppPage,
    appType,
    newAppBack,
    initBack,
    defaultApp,
} from '@/lib/appMethod';
import headeBar from '@/components/app/headBar';
import instructions from '@/components/app/instructions';
import userMixin from '@/mixin/userMixin';
export default {
    components: {
        headeBar,
        instructions,
    },
    mixins: [userMixin],
    data() {
        return {
            showPage: true,
            seriesId: null,
            phoneShow: false,
            sendShow: false,
            cardList: [], //拉类型列表
            acindex: -1, //选中卡index
            num: 1,
            cardInfo: {}, //卡信息
            sendPopInfo: {}, //赠送弹窗
            rule: {},
            moreShow: false,
            confirmShow:false,
            success: false,
            points: 0,
        };
    },
    watch: {
        acindex(val) {
            this.num = 1;
            this.cardInfo = this.cardList[val];
        },
    },
    computed: {
        total() {
            if (this.cardList && this.acindex !== -1) {
                return this.num * parseInt(this.cardInfo.price);
            }
            return '--';
        },
    },
    methods: {
        newAppBack,
        jumpQuestion() {
            this.$router.push(
                '/common-article?isBackToH5=true&&key=771029689006292992'
            );
        },
        showTip() {
            this.$refs.instructions.setData(this.cardInfo);
        },
        getDetail() {
            return this.$axios
                .post(this.baseURLApp + '/cardgoods/getSeriesGoodsList', {
                    userId: this.userId,
                    token: this.token,
                    seriesId: this.seriesId,
                    saleChannel: '6'
                })
                .then((res) => {
                    this.cardList = res.data;
                    console.log(res.data);
                });
        },
        getRule() {
            this.$axios
                .post(this.baseURLApp + '/user/getConfiguration', {
                    userId: this.userId,
                    token: this.token,
                    key: 'gift_card_exchange_instructions',
                })
                .then((res) => {
                    console.log(res);
                    if (res.code == '1') {
                        this.rule = res.data;
                    }
                });
        },
        minus() {
            this.setVal(parseInt(this.num)-1)
        },
        plus() {
            this.setVal(parseInt(this.num)+1)
        },
        numChange(){
            if(!this.num){
                this.$toast('宝贝不能再少了')
                this.setVal(1);
            }
        },
        numInput(val) {
            this.setVal(val);
        },
        setVal(val) {
            if(isNaN(val)||val==='0'||val===0){
                this.$toast('宝贝不能再少了')
                val=1;
            }
            if (val > this.cardInfo.limitNum) {
                this.num = parseInt(this.cardInfo.limitNum);
                this.$toast('达到单次购买上限')
                return;
            }
            this.num = val;
        },
        setIndex(item, index) {
            if (item.inventory<=0) {
                this.$toast('该商品已兑换完');
                return;
            }
            this.acindex = index;
        },
        getSendInfo() {
            return this.$axios
                .post(this.baseURLApp + '/cardgoods/giftinfo', {
                    userId: this.userId,
                    token: this.token,
                    giftRelationId: this.cardInfo.giftRelationId,
                })
                .then((res) => {
                    this.sendPopInfo = res.data;
                    this.sendShow = true;
                });
        },
        getPoints() {
            this.$axios
                .post(
                    this.baseURLApp +
                        '/userDuration/queryUserDurationCondition/v1',
                    {
                        userId: this.userId,
                        token: this.token,
                    }
                )
                .then((res) => {
                    // console.log(res);
                    if (res.code == '1') {
                        this.points = res.data.duration;
                    }
                });
        },
        submit() {
            if(this.acindex===-1){
                this.$toast('请选择面额');
                return;
            }
            this.confirmShow=true;
            // this.$dialog
            //     .confirm({
            //         title: '确认兑换',
            //         message:
            //             '确认是否使用' +
            //             this.total +
            //             '鹿分兑换【' +
            //             this.cardInfo.goodsName +
            //             '】x' +
            //             this.num,
            //         messageAlign: 'left',
            //         confirmButtonText:'确认兑换',
            //         cancelButtonText:'我再想想'
            //     })
            //     .then(() => {
            //         this.buy();
            //     })
            //     .catch(() => {});
        },
        buy() {
            this.$toast.loading({
                duration: 0,
                forbidClick: true,
                loadingType:'spinner'
            });
            const data = this.cardInfo;
            const ps = {
                mouldId: data.goodsChildId,
                childHeadImg: data.img ? data.img[0] : '',
                extendsJson: JSON.stringify({
                    seriesName: data.seriesName,
                    scanCode: '0',
                    venueId: data.venueLimit,
                    appVersion: '2.0.90'
                }),
                extendsType: 4,
                goodsNum: this.num,
                subject: data.subject,
                // totalAmount: that.fixNumber(data.totalAmount * data.goodsNum),
                totalAmount: '',
                type: 12,
                userId: this.userId,
                token: this.token,
                openId: '',
                wxPayType: 'applet',
                isDuration: '1',
                isApp:'1'
            };

            console.log(ps);
            this.$axios
                .post(this.baseURLApp + '/wechat/createWechatOrder', ps)
                .then((res) => {
                    if(res.code == '1'){
                        this.$toast.clear();
                        this.confirmShow=false;
                        this.success=true;
                        this.getPoints();
                    }
                });
        },
    },
    async created() {
        this.seriesId = this.$route.query.seriesId;
        await this.$getUserId();
        if (this.userId && this.token) {
            this.getDetail().then(() => {
                // return;
                const gid = this.$route.query.goodsChildId;
                if (gid) {
                    const index = this.cardList.findIndex((d) => {
                        return d.goodsChildId == gid;
                    });
                    if(index>-1&&this.cardList[index].inventory>0){
                        this.acindex=index;
                    }
                    console.log(this.acindex);
                }else{
                    const sortList=this.cardList.filter(d=>{
                        return d.inventory>0
                    }).sort((a,b) => {
                        return a.price-b.price;
                    });
                    console.log(sortList)
                    this.acindex = this.cardList.findIndex((d) => {
                        return d.goodsChildId == sortList[0].goodsChildId;
                    });
                }
            });
            this.getRule();
            this.getPoints();
        }
    },
};
</script>

<style lang="less" scoped >
.page_pointsmalldetail {
    .goods_content {
        padding-bottom: 220px;
    }
    .pub_pd {
        padding: 0 32px;
        box-sizing: border-box;
    }
    .pointsquestion {
        text-align: right;
    }
    .base {
        margin-bottom: 40px;

        .card {
            box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
            background: url(https://chaolucoachwebstatic.chaolu.com.cn/static/img/wxXiaoChengXu/giftCardStore/a1eccf67-40f6-4c5e-9d58-4bbbab1e6983.jpg);
            background-size: cover;
            height: 394px;
            margin: 20px 20px 40px;
            border-radius: 24px;
            overflow: hidden;
            img {
                width: 100%;
                height: 100%;
            }
        }
        .proname {
            color: #242831;
            font-weight: bold;
            font-size: 36px;
        }
        .introduce {
            margin-top: 20px;
            font-size: 28px;
            color: #9aa1a9;
        }
        .goods_tagsList {
            margin-top: 16px;
            display: flex;
            flex-wrap: wrap;
            .goods_tagsList_item {
                color: #267dff;
                font-weight: bold;
                border: 1px solid #267dff;
                border-radius: 2px;
                line-height: 32px;
                padding: 0 8px;
                margin-right: 8px;
                margin-bottom: 4px;
                text-align: center;
                white-space: nowrap;
                box-sizing: border-box;
                font-size: 24px;
            }
        }
    }

    .send {
        padding: 36px 32px;
        .send_goodsimg {
            width: 204px;
            height: 126px;
            position: relative;
            img {
                width: 100%;
                height: 100%;
            }
        }
        .send_goodsname {
            margin-left: 20px;
            font-size: 28px;
        }
    }
    .select {
        .select_title {
            color: #9aa1a9;
            font-size: 28px;
            padding: 30px 0;
        }
        .select_list {
            display: flex;
            flex-wrap: wrap;
            .select_model {
                width: 32%;
                box-sizing: border-box;
                text-align: center;
                background: #f4f4f4;
                border-radius: 8px;
                margin: 0 2% 2% 0;
                position: relative;
                line-height: 84px;
                &:nth-child(3n + 3) {
                    margin-right: 0;
                }
            }
            .dis {
                background: #fff;
                border: 1px solid #d5d5d5;
                color: #999;
            }
            .ac {
                background: #ffde00;
                font-weight: bold;
                color: #242831;
            }
        }
        .cosub {
            width: 56px;
            height: 56px;
            background: #f4f4f4;
            border-radius: 50%;
            text-align: center;
            line-height: 56px;
            font-size: 24px;
            cursor: pointer;
            .van-icon {
                font-weight: bold;
            }
        }
        .num {
            width: 100px;
            text-align: center;
            font-weight: bold;
            /deep/ .van-cell {
                padding: 0;
            }
        }
        .disnum {
            color: #ccc;
            // pointer-events: none;
        }
        &.hidenum{
            padding-bottom: 30px;
        }
    }
    .info {
        font-size: 24px;
        .titleline {
            justify-content: center;
            padding: 50px 0;
        }
        .titlelogo {
            width: 24px;
            height: 24px;
        }
        .title {
            margin: 0 20px;
            font-size: 28px;
            color: #9aa1a9;
            height: 42px;
        }
        img {
            width: 100%;
        }
    }
    .footerbar {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        background: #fff;
        .notice {
            /deep/ .van-notice-bar__wrap {
                margin: 0 20px;
                font-size: 24px;
            }
        }
        .subline {
            overflow: hidden;
            line-height: 96px;
            font-size: 32px;
            font-weight: bold;
            background: #fff;
            padding: 16px 32px;
            box-sizing: border-box;
        }
        .subleft {
            background: #3c454e;
            color: #fff;
            flex: 1;
            padding-left: 40px;
            border-top-left-radius: 12px;
            border-bottom-left-radius: 12px;
        }
        .subright {
            background: #ffde00;
            color: #242831;
            width: 232px;
            text-align: center;
            border-top-right-radius: 12px;
            border-bottom-right-radius: 12px;
        }
        .dissub {
            background: #d5d5d5;
            pointer-events: none;
        }
    }
    .phonelist {
        background: #f5f5f5;
        line-height: 80px;
        text-align: center;
        .phoneline {
            background: #fff;
            display: block;
            color: #242831;
        }
        .phonecancel {
            margin-top: 20px;
            background: #fff;
        }
    }
    .sendbox {
        padding: 40px;
        .sendbox_img {
            width: 574px;
            height: 344px;
            border-radius: 24px;
            background: url(https://chaolucoachwebstatic.chaolu.com.cn/static/img/wxXiaoChengXu/giftCardStore/a1eccf67-40f6-4c5e-9d58-4bbbab1e6983.jpg);
            background-size: cover;
            img {
                width: 100%;
                height: 100%;
            }
        }
        .sendbox_title {
            font-size: 34px;
            font-weight: bold;
            color: #242831;
            text-align: center;
            margin: 40px 0 30px;
        }
        .sendbox_intro {
            font-size: 28px;
            color: #666;
        }
        .sendbox_sub {
            background: #ffde00;
            text-align: center;
            color: #242831;
            line-height: 88px;
            border-radius: 8px;
            margin-top: 40px;
        }
    }
    .successbox {
        width: 630px;
        text-align: center;
        background: transparent;
        .successbox_close {
            text-align: right;
            margin-bottom: 24px;
            .cross {
                border: 1px solid #fff;
                width: 60px;
                line-height: 60px;
                text-align: center;
                border-radius: 50%;
            }
        }
        .pdc {
            background: #fff;
            border-radius: 24px;
            padding-bottom: 56px;
        }
        .successbox_img {
            width: 100%;
        }
        .successbox_title {
            color: #242831;
            font-size: 36px;
            font-weight: bold;
            margin-top: 20px;
        }
        .successbox_tips {
            font-size: 28px;
            color: #9aa1a9;
            margin: 30px 0;
        }
        .successbox_sub {
            background: #ffde00;
            text-align: center;
            color: #242831;
            line-height: 96px;
            border-radius: 8px;
            margin: 60px auto 0;
            width: 510px;
            font-weight: bold;
        }
    }
    .confirmbox{
        width: 590px;
        box-sizing: border-box;
        padding: 40px;
        text-align: center;
        background: transparent;
        background: #fff;
        border-radius: 16px;
        .confirm_title{
            color: #242831;
            font-size: 36px;
            font-weight: bold;
        }
        .confirm_tips{
            text-align: center;
            color: #6C727A;
            font-size: 24px;
            margin: 32px 0 40px;
            line-height: 38px;
        }
        .confirmsub{
            width: 242px;
            line-height: 96px;
            border-radius: 8px;
            text-align: center;
            color: #3C454E;
            font-size: 32px;
            box-sizing: border-box;
        }
        .subac{
            background: #FFDE00;
            color: #242831;
            font-weight: bold;
        }
    }
}
</style>
