<template>
    <div class="page_pointsmall iphonex-pb">
        <headeBar
            left-arrow
            :color="header_color"
            :title="header_title"
            :background="header_background"
            :opacity="header_opacity"
            :placeholder="false"
            @click-left="newAppBack"
        />
        <div>
            <img
                src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/pointsMall/head.png"
                class="page_pointsmall_head"
            />
            <div class="tabar pub_full">
                <div class="pub_onefull ptb">
                    <div class="BebasNeueBold points">{{ points }}</div>
                    <div class="tabar_label">当前可用鹿分</div>
                </div>
                <div
                    class="pub_onefull ptb van-hairline--left"
                    @click="togame"
                    v-if="appTypeStr !== 'mini'"
                >
                    <div class="tabar_game pub_full pub_flexmiddle">
                        <img
                            src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/pointsMall/game.png"
                            class="game_logo"
                        />
                        <div>鹿分摇摇机</div>
                    </div>
                    <div class="tabar_label">小积分，博大奖</div>
                </div>
            </div>
            <template v-if="hasData" >
                <div class="box_pd" >
                    <div v-for="item in groupList" :key="item.groupName">
                        <div class="pub_avg pub_flexmiddle goods_type">
                            <div class="goods_title">{{ item.groupName }}</div>
                            <div
                                @click="tomore(item.items[0])"
                                v-if="
                                    item.showAllDenomination &&
                                    item.items.length > 0
                                "
                            >
                                <span class="goods_more">更多</span>
                                <van-icon
                                    name="arrow"
                                    color="#9AA1A9"
                                    size="12px"
                                    class="arrow"
                                />
                            </div>
                        </div>
                        <div class="goods_list">
                            <div
                                class="goods_model"
                                @click="todetail(model)"
                                v-for="model in item.items"
                                :key="model.goodsChildId"
                            >
                                <div class="goods_coner" v-if="model.cornerLabel">
                                    <div class="smallfont">
                                        {{ model.cornerLabel }}
                                    </div>
                                </div>
                                <img :src="model.img" class="goods_img" />
                                <div class="goods_name">
                                    {{ model.productName }}
                                </div>
                                <div class="goods_tagsList">
                                    <div
                                        v-for="(item, index) in model.tagsList"
                                        :key="index"
                                        class="goods_tagsList_item"
                                    >
                                        <div class="smallfont2">{{ item }}</div>
                                    </div>
                                </div>
                                <div
                                    class="pub_full pub_flexmiddle goods_value_line"
                                >
                                    <img
                                        src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/pointsMall/logo.png"
                                        class="goods_logo"
                                    />
                                    <div class="goods_value">{{ model.price }}</div>
                                    <div>鹿分<span class="copies">/张</span></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="goods_tip">更多商品陆续上架中，敬请期待</div>
                </div>
            </template>
            <emptyPage
                v-else
                class="empty"
                fontSize="12"
                text="更多商品陆续上架中，敬请期待"
            />
        </div>
    </div>
</template>

<script>
import headeBar from '@/components/app/headBar';
import emptyPage from '@/components/app/emptyPage';
import { newAppBack, initBack, gotoAppPage, defaultApp } from '@/lib/appMethod';
import userMixin from '@/mixin/userMixin';
import headerMixin from '@/mixin/headerMixin';
export default {
    components: {
        headeBar,
        emptyPage,
    },
    mixins: [userMixin, headerMixin],
    data() {
        return {
            points: '--',
            groupList: [],
            hasData: true
        };
    },
    methods: {
        newAppBack,
        togame() {
            // const url="https://wxs.chaolu.com.cn/lockydraw/index?activityId=2&userId="+this.userId;
            this.$toast.loading({
                duration: 1000,
                forbidClick: true,
                loadingType:'spinner'
            });
            defaultApp();
            // location.href="http://192.168.0.41:8000/";return;
            this.$toast.loading({
                duration: 1000,
                forbidClick: true,
                loadingType:'spinner'
            });
            const url =
                this.baseURL +
                '/lockydraw/index?activityId=2&userId=' +
                this.userId;
            location.href = url;
        },
        getPoints() {
            this.$axios
                .post(
                    this.baseURLApp +
                        '/userDuration/queryUserDurationCondition/v1',
                    {
                        userId: this.userId,
                        token: this.token,
                    }
                )
                .then((res) => {
                    // console.log(res);
                    if (res.code == '1') {
                        this.points = res.data.duration;
                    }
                });
        },
        tomore(item) {
            this.$router.push({
                path: '/pointsMall/detail',
                query: {
                    seriesId: item.seriesId,
                },
            });
        },
        todetail(item) {
            console.log(item);
            this.$router.push({
                path: '/pointsMall/detail',
                query: {
                    seriesId: item.seriesId,
                    goodsChildId: item.goodsChildId,
                },
            });
        },
        getData() {
            this.$axios
                .post(this.baseURLApp + '/cardgoods/getGoodsList', {
                    userId: this.userId,
                    token: this.token,
                    // cityId: "3",
                    promoterCity: '1',
                    promotionOnly: '6',
                })
                .then((res) => {
                    console.log(res.data.groupList);
                    this.groupList = res.data.groupList;
                    this.hasData = res.data.groupList&&res.data.groupList.length > 0;
                });
        },
    },
    mounted() {
        this.bindHeaderScroll(window, 200, (scrollTop, morehalf) => {});
    },
    async created() {
        await this.$getUserId();
        if (this.userId && this.token) {
            initBack();
            this.getPoints();
            this.getData();
        } else {
        }
    },
    beforeRouteLeave(to, from, next) {
        // this.scrolltop = this.$refs['content'].$el.scrollTop;
        next();
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            if (from.path != '/') {
                vm.getPoints();
            }
        });
    },
};
</script>

<style lang="less" scoped >
.page_pointsmall {
    background: #f5f5f5;
    min-height: 100vh;
    box-sizing: border-box;
    .smallfont {
        font-size: 24px;
        transform: scale(0.84);
    }
    .smallfont2 {
        font-size: 24px;
        transform: scale(0.66);
        margin: 0 -8px;
    }
    .page_pointsmall_head {
        width: 100%;
    }
    .tabar {
        position: relative;
        background: #fff;
        border-radius: 8px;
        text-align: center;
        margin: -80px 32px 0;
        .ptb {
            padding: 38px 0;
        }
        .points {
            color: #8a552a;
            font-size: 56px;
            height: 56px;
        }
        .tabar_game {
            font-size: 28px;
            color: #242831;
            height: 56px;
            justify-content: center;
            font-weight: bold;
        }
        .game_logo {
            width: 28px;
            height: 28px;
            margin-right: 16px;
        }
        .tabar_label {
            color: #6c727a;
            margin-top: 4px;
            font-size: 24px;
            transform: scale(0.84);
            height: 28px;
        }
    }
    .box_pd {
        background: #f5f5f5;
        padding: 0 32px;
        position: relative;
        z-index: 2;
        .goods_type {
            margin: 48px 0 32px;
        }
        .goods_title {
            font-size: 28px;
            color: #242831;
            font-weight: bold;
            line-height: 32px;
        }
        .goods_more {
            font-size: 22px;
            color: #9aa1a9;
        }
        .goods_list {
            display: flex;
            flex-wrap: wrap;
            .goods_model {
                width: calc(50% - 8px);
                box-sizing: border-box;
                padding: 24px;
                background: #fff;
                border-radius: 8px;
                margin-bottom: 16px;
                position: relative;
                .goods_coner {
                    position: absolute;
                    top: 0;
                    left: -8px;
                    background: linear-gradient(90deg, #FF7860 0%, #ED5C42 100%);
                    color: #fff;
                    border-radius: 8px;
                    border-bottom-left-radius: 0;
                    line-height: 36px;
                    padding: 0 12px;
                    font-weight: bold;
                    z-index: 1;
                    &::before {
                        content: '';
                        position: absolute;
                        z-index: 1;
                        bottom: 0;
                        height: 1px;
                        background: #ff7860;
                        left: 0;
                        width: 8px;
                    }
                    &::after {
                        content: '';
                        position: absolute;
                        width: 0px;
                        height: 0px;
                        top: 100%;
                        border-top: 0px solid transparent;
                        border-bottom: 8px solid transparent;
                        border-right: 8px solid #D13114;
                        left: 0;
                        z-index: 0;
                        margin-top: -1px;
                        // transform: translateY(-2px);
                    }
                }
                .goods_img {
                    height: 172px;
                    border-radius: 8px;
                    width: 100%;
                }
                &:nth-child(2n + 1) {
                    margin-right: 16px;
                }
                .goods_name {
                    font-size: 24px;
                    color: #242831;
                    margin: 24px 0 0;
                    font-weight: bold;
                    line-height: 28px;
                }
                .goods_tagsList {
                    margin-top: 16px;
                    display: flex;
                    flex-wrap: wrap;
                    .goods_tagsList_item {
                        color: #267dff;
                        font-weight: bold;
                        border: 1px solid #267dff;
                        border-radius: 2px;
                        line-height: 32px;
                        padding: 0;
                        margin-right: 8px;
                        margin-bottom: 4px;
                        text-align: center;
                        white-space: nowrap;
                        box-sizing: border-box;
                        min-width: 50px;
                    }
                }
                .goods_value_line {
                    margin-top: 22px;
                    color: #8a552a;
                    font-size: 20px;
                    font-weight: bold;
                    line-height: 1;
                    overflow: visible;
                    .goods_logo {
                        width: 28px;
                        height: 28px;
                    }
                    .goods_value {
                        margin: 0 8px;
                        font-size: 28px;
                    }
                    .copies {
                        color: #6c727a;
                    }
                }
            }
        }
    }
    .goods_tip {
        font-size: 22px;
        color: #9aa1a9;
        text-align: center;
        // position: fixed;
        bottom: 0;
        padding: 48px 0;
        z-index: 1;
        left: 0;
        width: 100%;
        font-weight: bold;
    }
    .empty {
        margin-top: 173px;
    }
}
</style>
